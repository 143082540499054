import { useState } from "react";
import useAudioCapture from "../hooks/useAudioCapture";

export default function Test() {
    const { capturing, startCapture, stopCapture, inputs, setInput } = useAudioCapture();
    const [url, setUrl] = useState('');

    const stopRecording = async () => {
        const url = await stopCapture();
        setUrl(url);
    };

    return (
        <div>
            <select onChange={(e) => setInput(e.target.value)}>
                {inputs.map(input => (
                    <option key={input.deviceId} value={input.deviceId}>
                        {input.label}
                    </option>
                ))}
            </select>

            <button onClick={() => capturing ? stopRecording() : startCapture()}>
                {capturing ? 'Stop' : 'Start'}
            </button>

            {url && <audio controls autoPlay
                src={url}
                style={{ width: '100%' }} />}
        </div>
    );
}