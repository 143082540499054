import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ChakraProvider } from '@chakra-ui/react';
import StoreProvider from './store';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Apps from './pages/Apps';
import App from './pages/App';
import Test from './pages/Test';
import Loading from './pages/Loading';
import { DevTools, FormatSimple, Tolgee, TolgeeProvider } from '@tolgee/react';
import theme from './theme';
import enTranslation from './i18n/en.json';
import frTranslation from './i18n/fr.json';
import nlTranslation from './i18n/nl.json';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const router = createBrowserRouter([
	{
		path: '/',
		element: <Apps />
	},
	{
		path: '/loading',
		element: <Loading />
	},
	{
		path: '/:appName',
		element: <App />
	},
	{
		path: '/test',
		element: <Test />
	}
]);

console.log('API URL:', process.env.REACT_APP_TOLGEE_API_URL);
console.log('API KEY:', process.env.REACT_APP_TOLGEE_API_KEY);

//get browser language
const browserLanguage = navigator.language 
	? navigator.language.split('-')[0] 
	: 'en';

console.log('Browser language:', browserLanguage);	

const tolgee = Tolgee().use(DevTools()).use(FormatSimple()).init({
	language: browserLanguage,
	staticData: {
		en: enTranslation,
		fr: frTranslation,
		nl: nlTranslation
	  }
});

root.render(
	<TolgeeProvider tolgee={tolgee} 
	 fallback="Loading...">
		<ChakraProvider theme={theme}>
			<StoreProvider>
				<RouterProvider router={router} />
			</StoreProvider>
		</ChakraProvider>
	</TolgeeProvider>
);
