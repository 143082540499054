import { useEffect, useState } from "react";
import { IMediaRecorder, MediaRecorder, register } from 'extendable-media-recorder';
import { connect, disconnect } from 'extendable-media-recorder-wav-encoder';

export default function useAudioCapture() {
    const [capturing, setCapturing] = useState(false);
    const [desktopStream, setDesktopStream] = useState<MediaStream>();
    const [microphoneStream, setMicrophoneStream] = useState<MediaStream>();
    const [recorder, setRecorder] = useState<IMediaRecorder>();
    const [chunks, setChunks] = useState<Blob[]>([]);
    const [input, setInput] = useState('');
    const [inputs, setInputs] = useState<MediaDeviceInfo[]>([]);
    const [port, setPort] = useState<MessagePort>();

    useEffect(() => {
        (async () => {
            await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
            navigator.mediaDevices.enumerateDevices().then(devices => {
                const audioInputs = devices.filter(device => device.kind === 'audioinput');
                setInputs(audioInputs);
            });
        })();
    }, []);

    const startCapture = async (options?: DisplayMediaStreamOptions): Promise<{ recorder?: IMediaRecorder, combined?: MediaStream, desktop?: MediaStream, microphone?: MediaStream }> => {
        if (!port) {
            const port = await connect();
            setPort(port);
            await register(port);
        }
        try {
            const audioContext = new AudioContext();
            const deskStream = desktopStream || await navigator.mediaDevices.getDisplayMedia({ audio: { echoCancellation: false, autoGainControl: true, noiseSuppression: false } });
            const micStream = microphoneStream || await navigator.mediaDevices.getUserMedia({ audio: { deviceId: { exact: input } } });

            const deskSource = audioContext.createMediaStreamSource(deskStream);
            const micSource = audioContext.createMediaStreamSource(micStream);

            const merger = audioContext.createChannelMerger(2);
            const streams_dest = audioContext.createMediaStreamDestination();
            merger.connect(streams_dest);

            deskSource.connect(merger, 0, 0);
            micSource.connect(merger, 0, 1);

            const recorder = new MediaRecorder(streams_dest.stream, { mimeType: 'audio/wav' });
            recorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    setChunks(prevChunks => [...prevChunks, event.data]);
                }
            };

            recorder.start(100);

            setRecorder(recorder);
            setDesktopStream(deskStream);
            setMicrophoneStream(micStream);
            setCapturing(true);

            return {
                recorder: recorder,
                combined: streams_dest.stream,
                desktop: deskStream,
                microphone: micStream,
            };
        } catch (err) {
            console.error(`Error: ${err}`);
            return {};
        }
    };

    const stopCapture = () => {
        return new Promise<string>((resolve, reject) => {
            if (recorder) {
                recorder.onstop = () => {
                    const blob = new Blob(chunks, { type: 'audio/wav' });
                    const url = URL.createObjectURL(blob);

                    setCapturing(false);
                    setRecorder(undefined);
                    setChunks([]);

                    resolve(url);
                };

                recorder.stop();
            } else {
                reject('No recorder found');
            }
        });
    };

    return { capturing, startCapture, stopCapture, setInput, inputs };
}